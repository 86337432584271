import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'home',
    component: resolve => require(['../views/home-index.vue'], resolve)
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['../views/login.vue'], resolve)
  },
  {
    path: '/team-info',
    name: 'team-info',
    component: resolve => require(['../views/team-info/index.vue'], resolve),
    redirect: '/team-info/established',
    children: [
      {
        path: '/team-info/established',
        name: 'established',
        component: resolve => require(['../views/team-info/established.vue'], resolve)
      },
      {
        path: '/team-info/to-audit',
        name: 'to-audit',
        component: resolve => require(['../views/team-info/to-audit.vue'], resolve)
      },
      {
        path: '/team-info/refused',
        name: 'refused',
        component: resolve => require(['../views/team-info/refused.vue'], resolve)
      },
      {
        path: '/team-info/abarbeitung',
        name: 'abarbeitung',
        component: resolve => require(['../views/team-info/abarbeitung.vue'], resolve)
      }
    ]
  },
  {
    path: '/id-reviews',
    name: 'id-reviews',
    component: resolve => require(['../views/id-review/id-reviews.vue'], resolve)
  },
  {
    path: '/id-review',
    name: 'id-review',
    component: resolve => require(['../views/id-review/id-review.vue'], resolve)
  },
  {
    path: '/tips-admin',
    name: 'tipsAdmin',
    component: resolve => require(['../views/tips/main.vue'], resolve),
    children: [
      {
        path: 'task',
        name: 'task',
        meta: { title: '任务消息' },
        component: resolve => require(['../views/tips/infos/list.vue'], resolve)
      },
      {
        path: 'system',
        name: 'system',
        meta: { title: '系统消息' },
        component: resolve => require(['../views/tips/infos/list.vue'], resolve)
      },
      {
        path: ':type/edit-infos',
        name: 'edit-infos',
        component: resolve => require(['../views/tips/infos/edit.vue'], resolve)
      },
      {
        path: 'recruit/edit',
        name: '编辑招募公告',
        meta: { name: 'edit' },
        component: resolve => require(['../views/tips/recruit/edit.vue'], resolve)
      },
      {
        path: 'recruit/add',
        name: '添加招募公告',
        meta: { name: 'add' },
        component: resolve => require(['../views/tips/recruit/edit.vue'], resolve)
      },
      {
        path: 'recruit/history',
        name: '历史公告',
        component: resolve => require(['../views/tips/recruit/history.vue'], resolve)
      },
      {
        path: 'recruit/main',
        name: '线上公告',
        component: resolve => require(['../views/tips/recruit/recruiting.vue'], resolve)
      },
      {
        path: 'play-fanhan/new-guidance',
        name: '新手指导',
        component: resolve => require(['../views/tips/playFanhan/new-guidance/list.vue'], resolve)
      },
      {
        path: 'play-fanhan/new-guidance/add',
        name: '新手指导(新增)',
        meta: { name: 'add' },
        component: resolve => require(['../views/tips/playFanhan/new-guidance/edit.vue'], resolve)
      },
      {
        path: 'play-fanhan/new-guidance/edit',
        name: '新手指导(编辑)',
        meta: { name: 'edit' },
        component: resolve => require(['../views/tips/playFanhan/new-guidance/edit.vue'], resolve)
      },
      {
        path: 'play-fanhan/help-document',
        name: '帮助文档',
        component: resolve => require(['../views/tips/playFanhan/help-document/help-document.vue'], resolve)
      },
      {
        path: 'play-fanhan/help-document/add',
        name: '添加帮助文档',
        component: resolve => require(['../views/tips/playFanhan/help-document/addhelp-document.vue'], resolve)
      },
      {
        path: 'play-fanhan/about-fanhan',
        name: '关于泛函',
        component: resolve => require(['../views/tips/playFanhan/about-fanhan/about-fanhan.vue'], resolve)
      },
      {
        path: 'play-fanhan/about-fanhan/add',
        name: '添加关于泛函',
        component: resolve => require(['../views/tips/playFanhan/about-fanhan/addabout-fanhan.vue'], resolve)
      },
      {
        path: 'announce/team/online',
        name: '泛团线上公告',
        component: resolve => require(['../views/tips/announce/teamAnnounce/online.vue'], resolve)
      },
      {
        path: 'announce/team/history',
        name: '泛团历史公告',
        component: resolve => require(['../views/tips/announce/teamAnnounce/offline.vue'], resolve)
      },
      {
        path: 'announce/team/detail',
        name: '泛团公告详情',
        component: resolve => require(['../views/tips/announce/teamAnnounce/detail.vue'], resolve)
      },
      {
        path: 'announce/fahan/online',
        name: '泛函线上公告',
        component: resolve => require(['../views/tips/announce/fahanAnnounce/online.vue'], resolve)
      },
      {
        path: 'announce/fahan/history',
        name: '泛函历史公告',
        component: resolve => require(['../views/tips/announce/fahanAnnounce/offline.vue'], resolve)
      },
      {
        path: 'announce/fahan/add',
        name: '新增泛函公告',
        component: resolve => require(['../views/tips/announce/fahanAnnounce/add.vue'], resolve)
      },
      {
        path: 'announce/fahan/detail',
        name: '泛函公告详情',
        component: resolve => require(['../views/tips/announce/fahanAnnounce/detail.vue'], resolve)
      },
      {
        path: 'banner/main',
        name: 'bannerList',
        component: resolve => require(['../views/tips/banner/index.vue'], resolve)
      },
      {
        path: 'banner/edit',
        name: 'banner编辑',
        component: resolve => require(['../views/tips/banner/edit.vue'], resolve)
      },
      {
        path: 'banner/add',
        name: 'banner新增',
        component: resolve => require(['../views/tips/banner/edit.vue'], resolve)
      }
    ]
  },
  {
    path: '/limit',
    name: 'limit',
    component: resolve => require(['../views/limit/app.vue'], resolve),
    redirect: '/searchtuflag',
    children: [
      {
        path: '/roles-list',
        name: 'roles-list',
        component: resolve => require(['../views/limit/roles-list.vue'], resolve)
      },
      {
        path: '/searchtuflag',
        name: 'searchtuflag',
        component: resolve => require(['../views/limit/searchtuflag.vue'], resolve)
      },
      {
        path: '/addtuflag',
        name: 'addtuflag',
        component: resolve => require(['../views/limit/addtuflag.vue'], resolve)
      },
      {
        path: '/addgrouptuflag',
        name: 'addgrouptuflag',
        component: resolve => require(['../views/limit/addgrouptuflag.vue'], resolve)
      }
    ]
  },
  {
    path: '/points',
    name: 'points',
    redirect: '/points/log',
    component: resolve => require(['../views/points/main.vue'], resolve),
    children: [
      {
        path: 'log',
        name: 'log',
        component: resolve => require(['../views/points/log'], resolve)
      },
      {
        path: 'exchange',
        name: 'exchange',
        component: resolve => require(['../views/points/exchange'], resolve)
      },
      {
        path: 'sendteam',
        name: 'sendteam',
        component: resolve => require(['../views/points/sendteam'], resolve)
      },
      {
        path: 'senduser',
        name: 'senduser',
        component: resolve => require(['../views/points/senduser'], resolve)
      },
      {
        path: 'salarycheck',
        name: 'salarycheck',
        component: resolve => require(['../views/points/salarycheck'], resolve)
      },
      {
        path: 'userbill',
        name: 'userbill',
        component: resolve => require(['../views/points/userbill'], resolve)
      },
      {
        path: 'withdraw',
        name: '对公提现',
        component: resolve => require(['../views/points/withdraw'], resolve)
      },
      {
        path: 'withdraw-history',
        name: '历史记录',
        component: resolve => require(['../views/points/withdraw-history'], resolve)
      }
    ]
  },
  {
    path: '/report',
    name: 'report',
    component: resolve => require(['../views/report/main.vue'], resolve),
    children: [
      {
        path: 'task',
        name: 'task',
        component: resolve => require(['../views/report/task.vue'], resolve)
      },
      {
        path: 'user',
        name: 'user',
        component: resolve => require(['../views/report/user.vue'], resolve)
      },
      {
        path: 'question',
        name: 'question',
        component: resolve => require(['../views/report/question.vue'], resolve)
      },
      {
        path: 'sop',
        name: 'sop',
        component: resolve => require(['../views/report/sop.vue'], resolve)
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
