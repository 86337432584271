import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/iconfont.js'
import '@/assets/css/sticky-footer.css'
import '@/assets/css/main.css'
import 'v-charts/lib/style.css'
import IconFont from '@/components/IcontFont'
Vue.use(IconFont)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
